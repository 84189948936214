@font-face {
    font-family: "icons";
    src: url("./icons.eot?ea95fdb3197f98046518006492e960ae#iefix") format("embedded-opentype"),
url("./icons.woff2?ea95fdb3197f98046518006492e960ae") format("woff2"),
url("./icons.woff?ea95fdb3197f98046518006492e960ae") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-account:before {
    content: "\f101";
}
.icon-approved:before {
    content: "\f102";
}
.icon-arrow-down:before {
    content: "\f103";
}
.icon-arrow-next:before {
    content: "\f104";
}
.icon-arrow-prev:before {
    content: "\f105";
}
.icon-arrow-up:before {
    content: "\f106";
}
.icon-calendar:before {
    content: "\f107";
}
.icon-chat:before {
    content: "\f108";
}
.icon-check-icon:before {
    content: "\f109";
}
.icon-check:before {
    content: "\f10a";
}
.icon-delete:before {
    content: "\f10b";
}
.icon-down:before {
    content: "\f10c";
}
.icon-download:before {
    content: "\f10d";
}
.icon-duplicate-2:before {
    content: "\f10e";
}
.icon-duplicate:before {
    content: "\f10f";
}
.icon-edit:before {
    content: "\f110";
}
.icon-eye-slash:before {
    content: "\f111";
}
.icon-flag:before {
    content: "\f112";
}
.icon-land:before {
    content: "\f113";
}
.icon-location:before {
    content: "\f114";
}
.icon-matched:before {
    content: "\f115";
}
.icon-menu:before {
    content: "\f116";
}
.icon-notification:before {
    content: "\f117";
}
.icon-question:before {
    content: "\f118";
}
.icon-sea:before {
    content: "\f119";
}
.icon-search:before {
    content: "\f11a";
}
.icon-send:before {
    content: "\f11b";
}
.icon-settings:before {
    content: "\f11c";
}
.icon-ship:before {
    content: "\f11d";
}
.icon-split:before {
    content: "\f11e";
}
.icon-splittable:before {
    content: "\f11f";
}
.icon-star:before {
    content: "\f120";
}
.icon-trash:before {
    content: "\f121";
}
.icon-up:before {
    content: "\f122";
}
.icon-upload:before {
    content: "\f123";
}
.icon-warning-icon:before {
    content: "\f124";
}
